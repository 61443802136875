import client from "../../../feathers";
import { _base_axios_post, apiUrl } from "../../newApi";

export default function Login(query = {}, header) {
    return _base_axios_post(
        `${client.io.io.uri}custom-authentication`,
        query,
        "",
        header
    )
}