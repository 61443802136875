import {
  AndroidOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { Alert, Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { Link, navigate } from "gatsby";
import localStorage from "local-storage";
import { filter, isEmpty, set } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import client from "../../../../feathers";
import loginAPI from "../../../api/authentication/loginAPI";
import { globalLanguageImg, loginLogoImg } from "../../../images";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import {
  loginSuccessful,
  storeLoginRecord,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { languages } from "../../../utilities/profile";
import { apkLink, countryCode } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import SelectLanguageModal from "../../setting/components/SelectLanguageModal";
import Login from "../../../api/authentication/loginAPI2";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import { sourceKey } from "../../../locales/config";
// markup
const LoginPage = (props) => {
  // const location = useLocation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [captchaImg, setCaptchaImg] = useState('');
  const [maxLogin, setMaxLogin] = useState(3);
  const [visible, setVisible] = useState(false);
  const [accountWarningVisible, setAccountWarningVisible] = useState(false);

  // const [showError, setShowError] = useState();
  const { t } = useTranslation();
  let locales = "";
  locales = localStorage.get("locale");

  useEffect(() => {
    if (isEmpty(locales)) {
      let indo = filter(languages, (item, index) => {
        return get(item, "value") === "indo";
      });
      indo = indo[0];
      setCurrentLanguage(indo);
    } else {
      let selectedLanguage = filter(languages, (item, index) => {
        return get(item, "value") === locales;
      });
      selectedLanguage = selectedLanguage[0];
      setCurrentLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    generateRndm();
  }, [])

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
      setAccountWarningVisible(false);
    }
  }

  function convertText(text) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 160;
    canvas.height = 40;

    context.fillStyle = 'black';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.font = '24px Lato';
    context.fillStyle = 'white';

    const textMetrics = context.measureText(text);
    const textWidth = textMetrics.width;
    const textHeight = textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent;

    const x = (canvas.width - textWidth) / 2;
    const y = (canvas.height + textHeight) / 2;

    context.fillText(text, x, y);

    context.strokeStyle = 'red';
    context.lineWidth = 1;

    for (let i = 0; i < 5; i++) {
      const strikeY = Math.floor(Math.random() * canvas.height);
      const strikeX = Math.floor(Math.random() * canvas.width);

      const lineType = Math.floor(Math.random() * 3);

      context.beginPath();
      if (lineType === 0) {
        // Horizontal line
        context.moveTo(0, strikeY);
        context.lineTo(canvas.width, strikeY);
      } else if (lineType === 1) {
        // Vertical line
        context.moveTo(strikeX, 0);
        context.lineTo(strikeX, canvas.height);
      } else {
        // Diagonal line 
        context.moveTo(0, 0);
        context.lineTo(canvas.width, canvas.height);
      }
      context.stroke();
    }

    const dataUrl = canvas.toDataURL('image/png');

    return dataUrl;
  }

  const generateRndm = () => {
    let uniqueStr = '';
    const rndmStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 1; i <= 5; i++) {
      uniqueStr += rndmStr.charAt(Math.floor(Math.random() * rndmStr.length));
    }
    setCaptcha(uniqueStr);

    const imgDataUrl = convertText(uniqueStr);
    setCaptchaImg(imgDataUrl);
  };

  function submitLogin() {
    if (inputValue === captcha) {
      form
        .validateFields()
        .then((values) => {
          setLoginLoading(true);
          // console.log("values", values);
          Login({
            strategy: "local",
            ...values,
            countryCode,
          })
            .then((res) => {
              // console.log("res", res);
              let accessKey = get(res, "data.accessToken");
              localStorage.set("accessKey", accessKey);
              let user2 = res?.data?.user2;
              let profile_data = user2?.profile || 2;
              let userInfor = res?.data?.user;

              set(userInfor, "profile", user2?.profile);
              set(userInfor, "pairingUser", user2?.pairingUser);
              // console.log("userInfor",userInfor);

              message.success(t("loggingIn"));
              props.loginSuccessful(userInfor, accessKey);

              if (
                isEmpty(get(profile_data, "nickname")) ||
                isEmpty(get(profile_data, "contactNo")) ||
                isEmpty(get(profile_data, "email"))
              ) {
                message.info(t("completeProfile"), 5);
                window.location.assign(
                  routes.setting.to({
                    type: "forceFill",
                  })
                );
              } else {
                window.location.assign("/");
              }
              setLoginLoading(false);
            })
            .catch((err) => {
              setLoginLoading(false);
              message.error(t(err?.message));
              setInputValue('');
              generateRndm();
              const attemptedLogin = err?.error?.response?.data?.data?.attemptedLogin;
              if (attemptedLogin === maxLogin) {
                setAccountWarningVisible(true);
              } else if (err?.message === 'Blocking Login') {
                setVisible(true);
              }
            });
        })
        .catch((err) => {
          setLoginLoading(false);
          forEach(get(err, "errorFields"), (item) => {
            message.error(get(item, "errors[0]"));
          });
        });
    } else {
      message.error(t("notMatched"));
      setInputValue('');
      generateRndm();
    }
  }
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      submitLogin();
    }
  };
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          {/* <NavHeader showBack={false}> */}
          <div className="">
            <div className="relative">
              {/* <img
                src={loginBgImg}
                className="waveShape "
                style={{
                  width: "100%",
                  height: "301px",
                  // objectFit: "cover",
                  // filter: "blur(2px) brightness(0.5)",
                }}
              /> */}
              <div
                className="waveShape "
                style={{
                  width: "100%",
                  height: "301px",
                  background: "#3A3A3A",
                  // objectFit: "cover",
                  // filter: "blur(2px) brightness(0.5)",
                }}
              ></div>

              <div className="absolute" style={{ top: "34%", left: "41%" }}>
                <img
                  src={loginLogoImg}
                  style={{
                    width: "100px",
                    height: "100px",
                    // objectFit: "cover",
                    // filter: "blur(2px) brightness(0.5)",
                  }}
                />
                {/* <div>IndoITS</div> */}
              </div>
            </div>

            <div
              className=" relative p-5"
            // style={{ bottom: 245 }}
            >
              {/* <div className="flex justify-center">
                <img
                  src={newLogoImg}
                  style={{ width: "81px", height: "80px" }}
                />
              </div> */}
              {/* <div
                className="flex justify-center pt-5"
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  fontWeight: 600,
                }}
              >
                Intelligent Trading Solutions
              </div> */}

              <div className=" px-6">
                <div className="font-semibold text-base ">
                  {/* 登入 Sign In */}
                  {t("signIn")}
                </div>
                <Form form={form} style={{ color: "black" }}>
                  <div className="mt-5 mb-2">
                    {/* 邮件 */}
                    {/* <br></br> */}
                    {t("username")} :
                  </div>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {t("usernameRequired")}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (_, value) =>
                          !value.includes(" ")
                            ? Promise.resolve()
                            : Promise.reject(new Error(t("noSpace"))),
                      },
                      // {
                      //   type: "email",
                      //   message: (
                      //     <React.Fragment>
                      //       {/* 用户邮籍形式不正确
                      //         <br /> */}
                      //       {t("invalidEmail")}
                      //     </React.Fragment>
                      //   ),
                      // },
                    ]}
                  >
                    <Input
                      placeholder=""
                      className={"input-border"}
                      onKeyPress={handleKeypress}
                    />
                  </Form.Item>

                  <div className="mt-5 mb-2">
                    {/* 密码
                      <br></br> */}
                    {t("password")} :
                  </div>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写密码
                              <br /> */}
                            {t("passwordRequired")}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t("insertPassword")}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      className={"input-border"}
                      onKeyPress={handleKeypress}
                    />
                  </Form.Item>
                </Form>

                <div className="flex justify-center items-center gap-2">
                  <div>
                    <Input
                      placeholder={t("enterCaptcha")}
                      className="input-border"
                      style={{ height: '40px' }}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                  <div className="flex justify-center items-center" style={{ height: '32px', width: '32px' }}>
                    <SyncOutlined style={{ fontSize: 16 }} onClick={() => generateRndm()} />
                  </div>
                  <div className="" style={{ backgroundColor: 'white' }}>
                    <img src={captchaImg} />
                  </div>
                </div>

                <div className="flex justify-center items-center mt-8">
                  <Button
                    className="main-button-color button-padding "
                    // shape="round"
                    loading={loginLoading}
                    onClick={(e) => {
                      submitLogin();
                    }}
                    style={{ width: "100%", borderRadius: "40px" }}
                  >
                    {t("login")}
                  </Button>
                  {/* <Button
                    shape="round"
                    className="secondary-button-color"
                    onClick={(e) => {
                      if (typeof window !== `undefined`) {
                        navigate(routes.register.to());
                      }
                    }}
                  >
                    注册 REGISTER
                  </Button> */}
                </div>
              </div>

              <Link to={routes.forgotPassword.to()}>
                <div
                  className="flex justify-center items-center mt-5 underline "
                  style={{ color: "#00B2FF" }}
                >
                  {t("forgotPassword")}
                </div>
              </Link>

              <div className="flex justify-center items-center mt-3">
                <Button
                  icon={<AndroidOutlined style={{ fontSize: "20px" }} />}
                  className="main-button-color "
                  shape="round"
                  onClick={(e) => {
                    window.location.href = apkLink;
                  }}
                >
                  {" "}
                  ANDROID
                </Button>
                {/* <Button icon={<AndroidOutlined/>} className="main-button-color  mr-4" shape="round" onClick={(e) => {
                  }} > IOS</Button> */}
              </div>

              <div className="flex justify-center items-center mt-8">
                <div
                  className="rounded-3xl border-2 border-black px-2 py-1 flex cursor-pointer"
                  onClick={() => {
                    setSelectLanguage(true);
                  }}
                >
                  <span>
                    <img src={globalLanguageImg} width={30} height={30} />
                  </span>
                  <span className="pl-4 pt-1">
                    {!isEmpty(currentLanguage)
                      ? get(currentLanguage, "title")
                      : "English"}
                  </span>
                  <span className="pl-4 pt-1">
                    <DownOutlined />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* </NavHeader> */}

          <SelectLanguageModal
            visible={selectLanguage}
            onClose={() => {
              setSelectLanguage(false);
            }}
          // user={user}
          />
          <ConfirmationModal
            visible={visible}
            closable={false}
            closableOnMask={true}
            onClose={() => {
              close();
            }}
            confirmButton={() => {
              return (
                <div className="p-4 gap-1 flex flex-col w-full mt-auto">
                  <div
                    className="flex justify-center p-2"
                    style={{ borderRadius: '8px', background: "white", color: "black" }}
                    onClick={() => {
                      close();
                    }}
                  >
                    {t("confirm")}
                  </div>
                  <div
                    className="flex justify-center p-2"
                    style={{ borderRadius: '8px', background: "#FFFFFF1A", color: "white" }}
                    onClick={() => {
                      navigate(routes.forgotPassword.to());
                    }}
                  >
                    {t("forgotPassword")}
                  </div>
                </div>
              );
            }}
          >
            <div className="">
              <Alert
                className="flex items-center justify-center"
                type="error"
                showIcon
                style={{ background: "transparent", border: "none" }}
                message={
                  <div className="font-bold" style={{ color: "white" }}>
                    {t("notice", sourceKey.profile)}
                  </div>
                }
                description={
                  <div style={{ color: "white" }}>
                    <span>{t("accountLocked", sourceKey.profile)}</span>
                  </div>
                }
              />
            </div>
          </ConfirmationModal>
          <ConfirmationModal
            visible={accountWarningVisible}
            closable={false}
            closableOnMask={true}
            onClose={() => {
              close();
            }}
            confirmButton={() => {
              return (
                <div className="p-4 gap-1 flex flex-col w-full mt-auto">
                  <div
                    className="flex justify-center p-2"
                    style={{ borderRadius: '8px', background: "white", color: "black" }}
                    onClick={() => {
                      close();
                    }}
                  >
                    {t("confirm")}
                  </div>
                </div>
              );
            }}
          >
            <div className="p-4">
              <Alert
                type="warning"
                showIcon
                style={{ background: "transparent", border: "none" }}
                message={
                  <div className="font-bold" style={{ color: "white" }}>
                    {t("warning", sourceKey.profile)}
                  </div>
                }
                description={
                  <div style={{ color: "white" }}>
                    <span>{t("accountWarning", sourceKey.profile)}</span>
                  </div>
                }
              />
            </div>
          </ConfirmationModal>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  storeLoginRecord,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
